<script setup lang="ts">
    import { jwtDecode } from "jwt-decode";
    import { useAuthStore } from "~/stores/auth/useAuthStore";
    import { AuthTokenCookieName } from "~/types/consts/auth";
    const { t } = useI18n();

    useHead({ title: t('routes.main') });

    /** Временно не пускаем на главную страницу, а кидаем на профиль. */
    const user = useAuthStore();
    /**
    * Функция для навигации пользователя на его профиль по userId.
    * @param userId - Идентификатор пользователя, на которого нужно перейти.
    */
    const navigateToUser = (userId?: string): void => {
    if (!userId) {
        console.error("User ID is missing.");
        return;
    }
    navigateTo(`/users/${userId}`);
    };

    /**
    * Извлекает и декодирует идентификатор пользователя из JWT-токена, сохраненного в cookies.
    * @returns Идентификатор пользователя или null, если его невозможно извлечь.
    */
    const getUserIdFromToken = (): string | null => {
        const token = useCookie(AuthTokenCookieName);

        if (!token?.value) {
            console.error("Token is missing or invalid.");
            return null;
        }

        try {
            const decodedToken = jwtDecode(token.value) as { sub: string };
            return decodedToken?.sub || null;
        } catch (error) {
            console.error("Failed to decode token:", error);
            return null;
        }
    };

    /** Извлекает идентификатор пользователя либо из объекта пользователя, либо из токена. */
    const userId = user?.userId || getUserIdFromToken();
    
    /** Если userId доступен, перенаправляем на профиль. В противном случае выводим ошибку. */
    if (userId) {
        navigateToUser(userId);
    } else {
        console.error("Failed to retrieve user ID from both user object and token.");
    }
</script>

<template>
    <shared-parent-private>
        <label>{{ $t('main.hello') }}</label>
    </shared-parent-private>
</template>